.dot {
  min-width: 15px;
  min-height: 15px;
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel {
  position:absolute;
  top:0;
  z-index:99;
}
